<template>
  <a-layout-content class="m-4 scroll-x">
    <div class="container h-100">
      <a-row type="flex" justify="space-between" class="mb-4">
        <a-col v-if="cv">
          <h2>{{ `Cv for ${cv.name}` }}</h2>
        </a-col>
        <a-col>
          <a-select v-model="template" size="small" class="mr-2 dropdown-select">
            <a-select-option value="" disabled>Template format</a-select-option>
            <a-select-option v-for="option in templateOptions" :key="option" :value="option">
              {{ option }}
            </a-select-option>
          </a-select>

          <a-switch
            v-if="!cvIsEmpty || isPreviewCv"
            :checked="!!showDates"
            class="mr-2"
            checked-children="Project dates"
            un-checked-children="Project dates"
            default-unchecked
            @change="toggleDates"
          />

          <a-select
            v-model="dateFormat"
            :disabled="!showDates"
            size="small"
            class="mr-2 dropdown-select"
          >
            <a-select-option value="" disabled>Date format</a-select-option>
            <a-select-option v-for="option in dateFormatOptions" :key="option" :value="option">
              {{ formatDate(null, option) }}
            </a-select-option>
          </a-select>

          <a-switch
            v-if="!cvIsEmpty || isPreviewCv"
            :checked="!!showDuration"
            class="mr-2"
            checked-children="Project duration"
            un-checked-children="Project duration"
            default-unchecked
            @change="toggleDuration"
          />

          <a-switch
            v-if="(!cvIsEmpty || isPreviewCv) && template !== templateOptions[Template.LEGACY]"
            :checked="!!showEducations"
            class="mr-2"
            checked-children="Educations"
            un-checked-children="Educations"
            @change="toggleEducations"
          />

          <a-switch
            v-if="(!cvIsEmpty || isPreviewCv) && template !== templateOptions[Template.LEGACY]"
            :checked="!!showEducationsDescription"
            class="mr-2"
            checked-children="Educations Description"
            un-checked-children="Educations Description"
            @change="toggleEducationsDescription"
          />

          <a-switch
            v-if="!cvIsEmpty || isPreviewCv"
            :checked="!!showSkills"
            class="mr-2"
            checked-children="Project Skills"
            un-checked-children="Project Skills"
            @change="toggleProjectSkills"
          />

          <a-switch
            v-if="!cvIsEmpty && template !== templateOptions[Template.SKRY_V2]"
            :checked="!!showSkillCategories"
            class="mr-2"
            checked-children="Skill categories"
            un-checked-children="Skill categories"
            @change="toggleSkillCategories"
          />

          <a-switch
            v-if="!cvIsEmpty"
            :checked="!!showAreasOfExpertise"
            class="mr-2"
            checked-children="Areas of Expertise"
            un-checked-children="Areas of Expertise"
            @change="toggleAreasOfExpertise"
          />

          <a-switch
            v-if="!cvIsEmpty || isPreviewCv"
            :checked="!!showGithub"
            class="mr-2"
            checked-children="GitHub"
            un-checked-children="GitHub"
            @change="toggleGithub"
          />

          <a-switch
            v-if="(!cvIsEmpty || isPreviewCv) && template != 'Legacy'"
            :checked="!!showGravatar"
            class="mr-2"
            checked-children="Gravatar"
            un-checked-children="Gravatar"
            @change="toggleGravatar"
          />

          <a-switch
            v-if="!cvIsEmpty"
            :checked="!!isPreviewCv"
            class="mr-2"
            checked-children="Preview"
            un-checked-children="Preview"
            @change="togglePreview"
          />

          <a-switch
            v-if="!cvIsEmpty && template !== templateOptions[Template.SKRY_V2]"
            :checked="!!showSkillHighlights"
            class="mr-2"
            checked-children="Skill highlighting"
            un-checked-children="Skill highlighting"
            @change="toggleSkillHighlighting"
          />

          <a-button
            v-if="!cvIsEmpty || isPreviewCv"
            type="primary"
            class="mr-2"
            @click="handleEditCvVisible"
            >Edit CV</a-button
          >
          <a-button
            v-if="cvIsEmpty && isPreviewCv && canManageCv"
            type="primary"
            @click="handleAddCvVisible"
            >Save CV</a-button
          >
        </a-col>
      </a-row>

      <PreviewCvLegacy
        v-if="!cvIsEmpty && template === templateOptions[Template.LEGACY]"
        id="preview-cv"
        :cv="isPreviewCv ? previewCv : cv"
        :show-dates="showDates"
        :show-duration="showDuration"
        :show-skill-categories="showSkillCategories"
        :show-areas-of-expertise="showAreasOfExpertise"
        :show-skills="showSkills"
        :show-github="showGithub"
        :cv-background="cvBackground"
        :cv-motivation="cvMotivation"
        :show-skill-highlights="showSkillHighlights"
        :cv-logo="cvLogo"
        :format-project-date="formatProjectDate"
        :format-project-duration="formatProjectDuration"
      />

      <PreviewCvSkry
        v-if="!cvIsEmpty && template === templateOptions[Template.SKRY]"
        id="preview-cv"
        :cv="isPreviewCv ? previewCv : cv"
        :show-dates="showDates"
        :show-duration="showDuration"
        :show-skill-categories="showSkillCategories"
        :show-areas-of-expertise="showAreasOfExpertise"
        :show-skills="showSkills"
        :show-github="showGithub"
        :show-gravatar="showGravatar"
        :cv-background="cvBackground"
        :cv-motivation="cvMotivation"
        :format-project-date="formatProjectDate"
        :format-project-duration="formatProjectDuration"
        :show-educations="showEducations"
        :show-educations-description="showEducationsDescription"
        :show-skill-highlights="showSkillHighlights"
      />

      <PreviewCvSkryV2
        v-if="!cvIsEmpty && template === templateOptions[Template.SKRY_V2]"
        id="preview-cv"
        :cv="isPreviewCv ? previewCv : cv"
        :show-dates="showDates"
        :show-duration="showDuration"
        :show-areas-of-expertise="showAreasOfExpertise"
        :show-skills="showSkills"
        :show-github="showGithub"
        :show-gravatar="showGravatar"
        :cv-background="cvBackground"
        :cv-motivation="cvMotivation"
        :cv-logo="cvLogo"
        :format-project-date="formatProjectDate"
        :format-project-duration="formatProjectDuration"
        :show-educations="showEducations"
        :show-educations-description="showEducationsDescription"
      />

      <a-card v-if="cvIsEmpty && !isPreviewCv">
        <empty-resource-table resource="CV" />
        <a-row type="flex" justify="center">
          <a-button type="primary" @click="handleAddCvVisible">Create or Preview CV</a-button>
        </a-row>
      </a-card>

      <AddCv
        v-if="cvIsEmpty"
        :cv="cv"
        :visible="addCvVisible"
        :loading="loading"
        :loading-data="loadingData"
        :cv-data="cvData"
        :can-manage-cv="canManageCv"
        :acl="baseAcl"
        @close="addCvVisible = false"
        @update-preview-cv="handleUpdatePreviewCv"
        @update-cv="handleUpdateCv"
      />

      <EditCv
        v-if="!cvIsEmpty || isPreviewCv"
        :cv="isPreviewCv ? previewCv : cv"
        :visible="editCvVisible"
        :loading="loading"
        :loading-data="loadingData"
        :cv-data="cvData"
        :acl="baseAcl"
        @close="editCvVisible = false"
        @show-default-cv="handleShowDefaultCv"
        @update-preview-cv="handleUpdatePreviewCv"
        @update-cv="handleUpdateCv"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onMounted } from "vue";
import EmptyResourceTable from "../../common/_components/EmptyResourceTable.vue";
import {
  CvInterface,
  CvDataInterface,
  MinimalRoleCvInterface,
  MinimalWorkExperienceCvInterface,
  MinimalEducationCvInterface,
  MinimalSkillCategoryCvInterface,
} from "../types";
import PreviewCvLegacy from "../_components/templates/PreviewCvLegacy.vue";
import PreviewCvSkry from "../_components/templates/PreviewCvSkry.vue";
import { apiGetCv, apiUpdateCv, apiGetCvData } from "../_utils/api";
import { BaseAclInterface } from "@/modules/authentication/types";
import { RoleInterface } from "../../roles/types";
import EditCv from "../_components/EditCv.vue";
import { formatDatesDuration } from "@/utils";
import AddCv from "../_components/AddCv.vue";
import moment from "@/date";
import {
  CharacteristicInterface,
  CourseInterface,
  KnowledgeDomainInterface,
} from "../../consultant_profiles/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { useHead } from "@unhead/vue";
import { EducationInterface, WorkExperienceInterface } from "@/modules/users/types";
import { sortByCustomSortOrder } from "@/modules/cv/_utils/sorting";
import { SkillCategoryInterface } from "@/modules/skills/types";
import PreviewCvSkryV2 from "@/modules/cv/_components/templates/PreviewCvSkryV2.vue";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Enums
enum Template {
  SKRY_V2 = 0,
  SKRY = 1,
  LEGACY = 2,
}

// Data properties
const dateFormatOptions = ref<string[]>(["MMMM YYYY", "MMM YYYY", "YYYY-MM", "YYYY"]);
const templateOptions = ref<string[]>(["Skry V2", "Skry", "Legacy"]);
const cvData = ref<CvDataInterface | undefined>(undefined);
const previewCv = ref<CvInterface | undefined>(undefined);
const template = ref<string>(templateOptions.value[Template.SKRY_V2]);
const cv = ref<CvInterface | undefined>(undefined);
const cvMotivation = ref<string[] | null>([]);
const cvLogo = ref<string | undefined>(undefined);
const editCvVisible = ref<boolean>(false);
const addCvVisible = ref<boolean>(false);
const showDuration = ref<boolean>(true);
const loadingData = ref<boolean>(false);
const showGravatar = ref<boolean>(true);
const isPreviewCv = ref<boolean>(false);
const showSkillHighlights = ref<boolean>(false);
const showGithub = ref<boolean>(false);
const cvBackground = ref<string[]>([]);
const showDates = ref<boolean>(true);
const showSkills = ref<boolean>(true);
const loading = ref<boolean>(false);
const dateFormat = ref<string>("");
const showSkillCategories = ref<boolean>(true);
const showAreasOfExpertise = ref<boolean>(false);
const showEducations = ref<boolean>(false);
const showEducationsDescription = ref<boolean>(false);

// Instance
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $message = instance?.proxy.$message;

// Computed values
const cvIsEmpty = computed<boolean | undefined>(() => {
  return cv.value && (!cv.value.background || cv.value.knowledge_domains.length === 0);
});

const canManageCv = computed<boolean>(() => {
  return baseAcl.value.isAdmin || baseAcl.value.isCr || baseAcl.value.isHr;
});

// Lifecycle hooks
onMounted(() => {
  apiGetCv(parseInt($route?.params.id as string)).then((data: any) => {
    cv.value = data.data.data;
    if (cv.value) {
      cv.value.contact_email = "collaboration@skry.se";
      cv.value.contact_phone = "";
      sortAllSkills(cv.value);
    }
    if (!cvIsEmpty.value) {
      if (cv.value != null) updatePreviewCv(cv.value);
    }
  });
});

const title = computed<string>(() => {
  return `CV - ${cv.value?.name ?? ""}`;
});
useHead({
  title,
});

// Class Methods
const handleAddCvVisible = (): void => {
  addCvVisible.value = true;
  loadingData.value = true;
  if (cv.value != null)
    apiGetCvData(cv.value.id).then((data: any) => {
      cvData.value = data.data.data;
      loadingData.value = false;
    });
};

const handleEditCvVisible = (): void => {
  editCvVisible.value = true;
  loadingData.value = true;
  if (cv.value != null)
    apiGetCvData(cv.value.id).then((data: any) => {
      cvData.value = data.data.data;
      loadingData.value = false;
    });
};

const handleUpdateCv = (updatedCv: CvInterface): void => {
  loading.value = true;
  isPreviewCv.value = false;
  apiUpdateCv(updatedCv)
    .then((data: any) => {
      cv.value = data.data.data;
      if (cv.value) {
        cv.value.contact_email = updatedCv.contact_email;
        cv.value.contact_phone = updatedCv.contact_phone;
        cv.value.motivation = updatedCv.motivation;
        cv.value.custom_logo = updatedCv.custom_logo;
        sortAllSkills(cv.value);
      }
      if (cv.value != null) updatePreviewCv(cv.value);
      $message?.success("CV updated successfully!", 3);
      addCvVisible.value = false;
      editCvVisible.value = false;
    })
    .catch(() => $message?.error("Couldn't update cv!", 3))
    .finally(() => (loading.value = false));
};

const handleUpdatePreviewCv = (updatedCv: CvInterface): void => {
  let updatedPreviewCv: any = { ...previewCv.value };
  updatedPreviewCv.first_title = updatedCv.first_title;
  updatedPreviewCv.second_title = updatedCv.second_title;
  updatedPreviewCv.background = updatedCv.background;
  updatedPreviewCv.motivation = updatedCv.motivation;
  updatedPreviewCv.contact_email = updatedCv.contact_email;
  updatedPreviewCv.contact_phone = updatedCv.contact_phone;
  updatedPreviewCv.skills = updatedCv.skills;

  ["characteristics", "courses"].forEach((key) => {
    if (cvData.value != null)
      updatedPreviewCv[key] = cvData.value[key].filter(
        (item: CharacteristicInterface | CourseInterface) => {
          return updatedCv[key].some((id: number) => {
            return item.id === id;
          });
        }
      );
  });

  updatedPreviewCv.roles = updatedCv.roles.map((ru: MinimalRoleCvInterface) => {
    if (cvData.value != null) {
      const role = cvData.value.user.roles.find((r: RoleInterface) => r.id === ru.role_id);
      return {
        ...role,
        hide_client_name: ru.hide_client_name,
        custom_role_title: ru.custom_role_title,
      };
    }
  });

  updatedPreviewCv.work_experiences = updatedCv.work_experiences.map(
    (exp: MinimalWorkExperienceCvInterface) => {
      if (cvData.value != null) {
        const experience = cvData.value.user.work_experiences.find(
          (w: WorkExperienceInterface) => w.id === exp.work_experience_id
        );
        return {
          ...experience,
          custom_role_title: exp.custom_role_title,
        };
      }
    }
  );

  updatedPreviewCv.educations = updatedCv.educations.map((edu: MinimalEducationCvInterface) => {
    if (cvData.value != null) {
      const educations = cvData.value.user.educations.find(
        (e: EducationInterface) => e.id === edu.education_id
      );
      return {
        ...educations,
      };
    }
  });

  updatedPreviewCv.knowledge_domains = updatedCv.knowledge_domains.map((kdu: any) => {
    if (cvData.value != null) {
      const knowledgeDomain = cvData.value.knowledge_domains.find(
        (kd: KnowledgeDomainInterface) => kd.id === kdu.knowledge_domain_id
      );
      return {
        ...kdu,
        // @ts-ignore
        title: knowledgeDomain.title,
        // @ts-ignore
        description: knowledgeDomain[`description_${kdu.level}`],
      };
    }
  });

  updatedPreviewCv.skill_categories = updatedCv.skill_categories.map(
    (sc: MinimalSkillCategoryCvInterface) => {
      if (cvData.value != null) {
        const skillCategory = cvData.value.skill_categories.find(
          (s: SkillCategoryInterface) => s.id === sc.skill_category_id
        );
        const updatedCvSkillIds = updatedCv.skills.map((skill) => skill.id);
        // Filter the skills within the skillCategory to only include those that are in selectedSkillsIds
        const filteredSkills = skillCategory.skills.filter((skill) =>
          updatedCvSkillIds.includes(skill.id)
        );
        return {
          ...skillCategory,
          skills: filteredSkills,
        };
      }
    }
  );

  updatedPreviewCv.custom_logo = updatedCv.custom_logo;

  if (cvIsEmpty.value) {
    if (cv.value === null) return;
    updatedPreviewCv.name = cv.value?.name;
  }

  sortAllSkills(updatedPreviewCv);
  updatePreviewCv(updatedPreviewCv);
  isPreviewCv.value = true;
  addCvVisible.value = false;
  editCvVisible.value = false;
};

const handleShowDefaultCv = (): void => {
  isPreviewCv.value = false;
  addCvVisible.value = false;
  editCvVisible.value = false;
};

const updatePreviewCv = (updatedCv: CvInterface): void => {
  previewCv.value = JSON.parse(JSON.stringify(updatedCv));
  previewCv.value && sortAllSkills(previewCv.value);
  cvBackground.value = (updatedCv.background || "").split(/\n+/g);
  cvMotivation.value = updatedCv.motivation ? updatedCv.motivation.split(/\n+/g) : null;
  cvLogo.value = updatedCv.custom_logo;
};

const toggleDates = (): void => {
  showDates.value = !showDates.value;
};

const toggleDuration = (): void => {
  showDuration.value = !showDuration.value;
};

const togglePreview = (): void => {
  isPreviewCv.value = !isPreviewCv.value;
};

const toggleSkillHighlighting = (): void => {
  showSkillHighlights.value = !showSkillHighlights.value;
};

const toggleProjectSkills = (): void => {
  showSkills.value = !showSkills.value;
};

const toggleSkillCategories = (): void => {
  showSkillCategories.value = !showSkillCategories.value;
};

const toggleAreasOfExpertise = (): void => {
  showAreasOfExpertise.value = !showAreasOfExpertise.value;
};

const toggleGithub = (): void => {
  showGithub.value = !showGithub.value;
};

const toggleGravatar = (): void => {
  showGravatar.value = !showGravatar.value;
};

const toggleEducations = (): void => {
  showEducations.value = !showEducations.value;
};

const toggleEducationsDescription = (): void => {
  showEducationsDescription.value = !showEducationsDescription.value;
};

const formatDate = (date: any, format: string): string => {
  return moment(date || undefined).format(format || dateFormatOptions.value[0]);
};

const formatProjectDate = (start: any, end: any) => {
  if (dateFormat.value.startsWith("duration")) {
    const dayDiff = moment(end).diff(moment(start), "days");
    if (dateFormat.value === "duration-years") {
      const years = Math.round(dayDiff / 365);
      return `${years} year${years === 1 ? "" : "s"}`;
    } else if (dateFormat.value === "duration-months") {
      const months = Math.round(dayDiff / (365 / 12));
      return `${months} month${months === 1 ? "" : "s"}`;
    } else if (dateFormat.value === "duration-weeks") {
      const weeks = Math.round(dayDiff / (365 / 52));
      return `${weeks} week${weeks === 1 ? "" : "s"}`;
    }
  }
  const ongoingProject = !moment(end).isValid() || moment(end).diff(moment()) > 0;
  const format = dateFormat.value || "MMMM YYYY";
  return `${moment(start).format(format)} - ${
    ongoingProject ? "Ongoing" : moment(end).format(format)
  }`;
};

const formatProjectDuration = (start: string, end: string) => {
  return formatDatesDuration(
    moment(start).startOf("month").format("YYYY-MM-DD"),
    moment(end).endOf("month").format("YYYY-MM-DD"),
    { months: 1 }
  );
};

// Sort skills on work experiences, projects and skill categories by the order of all skills
const sortAllSkills = (cv: CvInterface) => {
  const sortSkills = <A,>(obj: A) => ({
    ...obj,
    skills: sortByCustomSortOrder(
      obj.skills,
      cv.skills,
      (obj) => obj.id,
      (s) => s.id
    ),
  });
  cv.work_experiences = cv.work_experiences.map(sortSkills);
  cv.roles = cv.roles.map(sortSkills);
  cv.skill_categories = cv.skill_categories.map(sortSkills);
};
</script>

<style lang="scss">
.dropdown-select {
  min-width: 150px;
}

@media not print {
  .scroll-x {
    overflow-x: scroll;
  }
}

@media print {
  .ant-layout-header {
    display: none !important;
  }

  .ant-layout-content.m-4 {
    margin: 0 !important;
  }

  .container {
    margin: 0 !important;
    padding: 0 !important;
    max-width: none !important;
  }

  .container > *:not(#preview-cv) {
    display: none !important;
  }

  .sidebar-wrapper,
  .sidebar-filler {
    display: none !important;
  }
}
</style>
