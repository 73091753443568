import axios from "@/network";
import { AxiosResponse } from "axios";
import { UserVacationYearInterface, VacationYearsFilterInterface } from "../types";

export async function apiGetAllUserVacationYears(
  filters: VacationYearsFilterInterface
): Promise<AxiosResponse<{ data: Array<UserVacationYearInterface> }>> {
  const url = `/vacation_years`;
  const params = filters;
  return axios.get(url, { params });
}
