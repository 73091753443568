<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>Vacation Years</h2>
        <portal-target name="vacation-years-filter-buttons"></portal-target>
      </div>
      <vacation-years-filter
        :number-of-results="vacationYears?.length ?? 0"
        @apply-filters="applyFilters"
      />
      <div class="card mb-3">
        <a-row>
          <a-col>
            <h3>
              <b>{{ selectedYear }}</b>
            </h3>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-table
              :data-source="vacationYears"
              :scroll="{ x: 1000 }"
              :pagination="false"
              :columns="vacationYearLabels"
            >
              <span slot="user" slot-scope="user" class="d-flex align-items-center">
                <span class="min-w-8 mr-2">
                  <a-avatar :src="user.gravatar" />
                </span>
                {{ user.name }}
              </span>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { UserVacationYearInterface, VacationYearsFilterInterface } from "../types";
import VacationYearsFilter from "../_components/VacationYearsFilter.vue";
import moment from "@/date";
import { apiGetAllUserVacationYears } from "../_utils/api";

const selectedYear = ref<number>(moment().year());
const vacationYears = ref<Array<UserVacationYearInterface>>();
let _allVacationYears: Array<UserVacationYearInterface> = [];
let _filters: VacationYearsFilterInterface = {
  year: moment().year(),
};

const vacationYearLabels = [
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    scopedSlots: { customRender: "user" },
  },
  {
    title: "Earned vacation days",
    dataIndex: "vacation_days_earned",
    key: "vacation_days_earned",
  },
  {
    title: "Paid vacation days",
    dataIndex: "vacation_days_paid",
    key: "vacation_days_paid",
  },
  {
    title: "Reported",
    dataIndex: "vacation_days_reported",
    key: "vacation_days_reported",
  },
  {
    title: "Vacation days saved",
    dataIndex: "vacation_days_saved",
    key: "vacation_days_saved",
  },
  {
    title: "Saved days used",
    dataIndex: "vacation_days_saved_used",
    key: "vacation_days_saved_used",
  },
  {
    title: "Paid out",
    dataIndex: "paid_out_vacation_days",
    key: "paid_out_vacation_days",
  },
  {
    title: "Prepaid",
    dataIndex: "vacation_days_prepaid",
    key: "vacation_days_prepaid",
  },
];

const isObjectDifferent = (a: any, b: any): boolean => {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return true;
  }
  for (const prop in a) {
    if (!b.hasOwnProperty(prop) || a[prop] !== b[prop]) {
      return true;
    }
  }
  return false;
};

const applyFilters = (filters: VacationYearsFilterInterface) => {
  if (isObjectDifferent(filters, _filters)) {
    fetchVacationYears(filters).then((fetchedYears) => {
      _allVacationYears = fetchedYears;
      vacationYears.value = formatVacationYears(_allVacationYears);
    });
    if (filters.year) {
      selectedYear.value = filters.year;
    }
    _filters = { ...filters };
  } else {
    vacationYears.value = formatVacationYears(_allVacationYears);
  }
};

const formatVacationYears = (allVacationYears: Array<UserVacationYearInterface>) => {
  return allVacationYears.map((_vacationYear) => ({
    ..._vacationYear,
    vacation_days_saved_used: _vacationYear.vacation_days_saved_used ?? "n/a",
    paid_out_vacation_days: _vacationYear.paid_out_vacation_days ?? "n/a",
  }));
};

const fetchVacationYears = async (
  filters: VacationYearsFilterInterface
): Promise<Array<UserVacationYearInterface>> => {
  return (await apiGetAllUserVacationYears(filters)).data.data;
};

const loadVacationYears = async (
  filters: VacationYearsFilterInterface = { year: moment().year() }
) => {
  _allVacationYears = await fetchVacationYears(filters);
  vacationYears.value = formatVacationYears(_allVacationYears);
};

onBeforeMount(() => {
  loadVacationYears();
});
</script>

<style lang="scss" scoped></style>
