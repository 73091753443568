<template>
  <div>
    <div class="card mb-3">
      <a-row>
        <a-col>
          <h2><b>Vacation Days</b></h2>
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-around" :gutter="[16, 24]" class="lg break-flex">
        <a-col>
          <a-row class="center-text">
            <a-col>
              <h4><b>Maximum vacation this year:</b></h4>
            </a-col>
            <a-col>
              <span>{{ maximumVacationDays }}</span>
              <span v-if="daysSaved && daysPrepaid">
                ({{ daysSaved }} saved, {{ daysPrepaid }} prepaid)
              </span>
              <span v-else-if="daysSaved"> ({{ daysSaved }} saved) </span>
              <span v-else-if="daysPrepaid"> ({{ daysPrepaid }} prepaid) </span>
            </a-col>
          </a-row>
        </a-col>
        <a-col type="flex" class="center-items center-content">
          <a-row class="center-text">
            <a-col>
              <h4><b>Days left to use this year:</b></h4>
            </a-col>
            <a-col>
              <b>{{ daysLeft }}</b>
              <b v-if="Math.min(daysSaved, daysLeft) && Math.min(daysPrepaid, daysLeft)">
                ({{ Math.min(daysLeft, daysSaved) }} saved,
                {{ Math.min(daysLeft, daysPrepaid) }} prepaid)
              </b>
              <b v-else-if="Math.min(daysSaved, daysLeft)">
                ({{ Math.min(daysLeft, daysSaved) }} saved)
              </b>
              <b v-else-if="Math.min(daysLeft, daysPrepaid)">
                ({{ Math.min(daysLeft, daysPrepaid) }} prepaid)
              </b>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-row class="center-text" :gutter="[0, 12]">
            <a-col>
              <h4><b>Reported vacation days this year:</b></h4>
              <span>{{ reportedDays }}</span>
            </a-col>
            <a-col>
              <div class="small-table">
                <div class="small-table-header">
                  <div>Start date</div>
                  <div>End date</div>
                  <div>Amount of days</div>
                </div>
                <div v-for="report in vacationReports" :key="report.id" class="small-table-row">
                  <div>{{ report.start_date }}</div>
                  <div>{{ report.end_date }}</div>
                  <div>{{ report.total_days }}</div>
                </div>
                <div v-if="vacationReports.length === 0" class="small-table-row">
                  <span class="span-2">No scheduled vacations</span>
                </div>
                <div class="small-table-row">&nbsp;</div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="card mb-3">
      <a-row>
        <a-col>
          <h2>Vacation Years</h2>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-table
            :data-source="historicalVacationYears"
            :scroll="{ x: 1000 }"
            :pagination="false"
            :columns="vacationYearLabels"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, watch, set, computed } from "vue";
import { apiGetReports, apiGetUserVacationYears } from "@/modules/users/_utils/api";
import { ProfileInterface } from "../../authentication/types";
import moment from "@/date";
import { useReportsStore } from "@/modules/reports/_store";
import { ReportInterface, PublicHolidayInterface, ReportType } from "@/modules/reports/types";
import { VacationInterface } from "../types";
import { computeNbBusinessDaysV2 } from "@/modules/reports/_utils/public-holidays";

// Props
const props = defineProps({
  profile: { type: Object as () => ProfileInterface, default: undefined },
});

// Data properties
const daysLeft = ref<number>(0);
const daysSaved = ref<number>(0);
const reportedDays = ref<number>(0);
const daysPrepaid = ref<number>(0);
const paidOut = ref<number>(0);
const vacationYears = ref<Array<VacationInterface>>([]);
const vacationReports = ref<Array<ReportInterface>>([]);
const publicHolidays = ref<Array<PublicHolidayInterface>>([]);
const reportsStore = useReportsStore();
const maximumVacationDays = ref<number>(0);
const currentYear = moment().year();

watch([publicHolidays, vacationReports, maximumVacationDays], () => {
  if (vacationReports.value.length > 0 && publicHolidays.value.length > 0) {
    vacationReports.value.forEach((report, index) => {
      const total_days = Math.floor(
        computeNbBusinessDaysV2(
          moment(report.start_date),
          moment(report.end_date),
          publicHolidays.value
        )
      );

      set(vacationReports.value, index, {
        ...report,
        total_days: total_days,
      });
    });
  }
});

// Lifecycle hooks
onBeforeMount(async () => {
  // Fetch all vacation years for the user
  apiGetUserVacationYears(props.profile.id).then((res) => {
    vacationYears.value = res.data.data;

    // Find the current year's data for specific computations
    const currentYearData = vacationYears.value.find((year) => year.year === currentYear);
    if (currentYearData) {
      const {
        vacation_days_paid,
        vacation_days_earned,
        saved_days_balance,
        vacation_days_reported,
        paid_out_vacation_days,
      } = currentYearData;
      const earnedOrPaid = Math.max(vacation_days_paid, vacation_days_earned);
      maximumVacationDays.value = earnedOrPaid + saved_days_balance;
      daysSaved.value = saved_days_balance;
      daysPrepaid.value = vacation_days_earned - vacation_days_paid;
      reportedDays.value = vacation_days_reported ?? 0;
      daysLeft.value = maximumVacationDays.value - reportedDays.value;
      paidOut.value = paid_out_vacation_days;
    }
  });

  apiGetReports({
    user_id: [props.profile.id],
    types: [ReportType.VACATION],
    start_date: firstDayOfTheCurrentYear(),
  }).then((res: any) => {
    vacationReports.value = res.data.data;
  });

  publicHolidays.value = await fetchPublicHolidaysForCurrentYear();
});

const historicalVacationYears = computed(() => {
  return vacationYears.value
    .filter((year) => year.year !== currentYear)
    .map((vacationYear) => ({
      ...vacationYear,
      vacation_days_saved_used: vacationYear.vacation_days_saved_used ?? "n/a",
      paid_out_vacation_days: vacationYear.paid_out_vacation_days ?? "n/a",
    }));
});

const vacationYearLabels = [
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
  },
  {
    title: "Earned vacation days",
    dataIndex: "vacation_days_earned",
    key: "vacation_days_earned",
  },
  {
    title: "Paid vacation days",
    dataIndex: "vacation_days_paid",
    key: "vacation_days_paid",
  },
  {
    title: "Reported",
    dataIndex: "vacation_days_reported",
    key: "vacation_days_reported",
  },
  {
    title: "Vacation days saved",
    dataIndex: "vacation_days_saved",
    key: "vacation_days_saved",
  },
  {
    title: "Saved days used",
    dataIndex: "vacation_days_saved_used",
    key: "vacation_days_saved_used",
  },
  {
    title: "Paid out",
    dataIndex: "paid_out_vacation_days",
    key: "paid_out_vacation_days",
  },
  {
    title: "Prepaid",
    dataIndex: "vacation_days_prepaid",
    key: "vacation_days_prepaid",
  },
];

const firstDayOfTheCurrentYear = (): string => {
  return moment([currentYear]).format("YYYY-MM-DD");
};

const fetchPublicHolidaysForCurrentYear = async (): Promise<Array<PublicHolidayInterface>> => {
  const allPublicHolidays = [];

  const yearFormatted = moment({ year: currentYear }).format("YYYY");
  const response: any = await reportsStore.getPublicAnnualHolidays(yearFormatted);
  allPublicHolidays.push(...response.data);

  return allPublicHolidays;
};
</script>

<style scoped lang="scss">
.small-table {
  font-size: 0.9em;
  border: 2px solid lightgray;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & > .small-table-header {
    background: rgb(250, 250, 250);
    border-bottom: 2px solid lightgray;
  }

  & > .small-table-header,
  & > .small-table-row {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1/4;
    text-align: center;
    padding: 0.4em 0.4em;

    & > .span-2 {
      grid-column: span 2;
    }
  }

  & > .small-table-row:nth-child(even) {
    background: rgb(238, 238, 238);
  }

  & > .small-table-row:nth-child(odd) {
    background: rgb(250, 250, 250);
  }
}

@media (max-width: 991px) {
  .lg {
    &.break-flex {
      display: grid;
    }
  }
}

.center-text {
  text-align: center;
}

.center-items[type="flex"] {
  display: flex;
  align-items: center;
}

.center-content {
  justify-content: center;
}
</style>
